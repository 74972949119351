import Vue from 'vue';
import Router from 'vue-router';

/* Router Modules */
// import vuetifyRouter from '@/demo/router/vuetify';
// import nestedRouter from '@/demo/router/nested';
// import componentsRouter from '@/demo/router/components';
// import authRouter from './modules/auth';
// import errorsRouter from './modules/errors';
// import permissionRouter from './modules/permission';

Vue.use(Router);

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 */

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its
 *                                child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    [expected] noCache: true     if true, the page will no be cached(default is false)
    [expected] breadcrumb: false if false, the item will hidden in breadcrumb(default is true)
    [expected] affix: true       if true, the tag will affix in the tags-view
  }
 */

export const constantRoutes = [
    {
        path: '/dashboard',
        alias: '/',
        name: 'dashboard',
        component: () => import('@/views/modules/Dashboard/index.vue'),
        meta: {
            title: 'Dashboard',
            icon: 'mdi-file-document',
            roles: ['ROLE_PAYCONNECT_VIEW_DASHBOARD', 'ROLE_TDM_VIEW_DASHBOARD']
        },
        children: [
            {
                path: '/payconnect-dashboard',
                name: 'payconnect-dashboard',
                component: () => import('@/views/modules/Dashboard/payconnectDashboard.vue'),
                meta: {
                    title: 'Payconnect Dashboard',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_PAYCONNECT_VIEW_DASHBOARD']
                },
            },
            {
                path: '/terminal-dashboard',
                name: 'terminal-dashboard',
                component: () => import('@/views/modules/Dashboard/terminalDashboard.vue'),
                meta: {
                    title: 'Terminal Module',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_VIEW_DASHBOARD']
                },
            },
        ],
    },
    {
        path: "/user-management",
        alias: '/user-management',
        name: 'user-management',
        component: () => import('@/views/modules/Users/index.vue'),
        meta: {
            title: 'User Accounts',
            icon: 'mdi-account-key',
            roles: ['ROLE_TDM_VIEW_USER', 'ROLE_TDM_UPDATE_USER']
        },
    },
    {
        path: "/audit-logs",
        alias: '/audit-logs',
        name: 'audit-logs',
        component: () => import('@/views/modules/AuditLogs/index.vue'),
        meta: {
            title: 'Audit Logs',
            icon: 'mdi-text-box-search',
            roles: ['ROLE_TDM_VIEW_AUDIT_LOGS']
        },
    },
    {
        path: "/group-merchant",
        alias: '/group-merchant',
        name: 'group-merchant',
        component: () => import('@/views/modules/GroupMerchant/index.vue'),
        meta: {
            title: 'Merchant Group',
            icon: 'mdi-account-group',
            roles: ['ROLE_PAYCONNECT_VIEW_GROUP_MERCHANT']
        },
    },
    {
        path: "/group-management",
        alias: '/group-management',
        name: 'group-management',
        beforeEnter() {
            window.open(`${process.env.VUE_APP_KEYCLOAK_ROLE}`,
                '_blank');
        },
        meta: {
            title: 'Groups & Roles',
            icon: 'mdi-tie',
            roles: ['ROLE_VIEW_GROUP', 'ROLE_UPDATE_GROUP', 'ROLE_VIEW_ROLE', 'ROLE_UPDATE_ROLE']
        },
    },
    {
        path: "/merchants",
        alias: '/merchants',
        name: 'merchants',
        component: () => import('@/views/modules/Merchants/index.vue'),
        meta: {
            title: 'Merchants',
            icon: 'mdi-store',
            roles: ['ROLE_TDM_VIEW_MERCHANT']
        },
    },
    {
        path: '/transactions',
        alias: '/transactions',
        name: 'transactions',
        component: () => import('@/views/modules/Transactions/index.vue'),
        meta: {
            title: 'Transactions',
            icon: 'mdi-file-document',
            roles: ['ROLE_PAYCONNECT_SEARCH_TRANSACTION', 'ROLE_TDM_SEARCH_TRANSACTION', 'ROLE_PSC_VIEW_TRANSACTION']
        },
        children: [
            {
                path: '/payconnect-transactions',
                name: 'payconnect-transactions',
                component: () => import('@/views/modules/Transactions/payconnectTransaction.vue'),
                meta: {
                    title: 'Payconnect Transactions',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_PAYCONNECT_SEARCH_TRANSACTION']
                },
            },
            {
                path: '/pos-transactions',
                name: 'pos-transactions',
                component: () => import('@/views/modules/Transactions/posTransaction.vue'),
                meta: {
                    title: 'Terminal Transactions',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_TRANSACTION', 'ROLE_PSC_VIEW_TRANSACTION']
                },
            },
            {
                path: '/cash-out-transactions',
                name: 'cash-out-transactions',
                component: () => import('@/views/modules/Transactions/cashOutTransaction.vue'),
                meta: {
                    title: 'Cash-Out Transactions',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_VIEW_CASHOUT_TRANSACTION']
                },
            },
            {
                path: '/seven-pay-transactions',
                name: 'seven-pay-transactions',
                component: () => import('@/views/modules/Transactions/SevenPayTransactions.vue'),
                meta: {
                    title: 'Seven Pay Transactions',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_SEVENPAY_SEARCH_TRANSACTION']
                },
            },
            {
                path: '/file-upload',
                name: 'file-upload',
                component: () => import('@/views/modules/Transactions/fileUpload.vue'),
                meta: {
                    title: 'Transactions via CSV',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_PAYCONNECT_SEARCH_TRANSACTION']
                },
            },
        ],
    },
    {
        path: '/reconciliation',
        alias: '/reconciliation',
        name: 'reconciliation',
        component: () => import('@/views/modules/Reconciliation/index.vue'),
        meta: {
            title: 'Settlement',
            icon: 'mdi-compare-horizontal',
            roles: ['ROLE_PAYCONNECT_SEARCH_RECON', 'ROLE_TDM_SEARCH_RECON']
        },
        children: [
            {
                path: '/qr-settlement',
                name: 'qr-settlement',
                component: () => import('@/views/modules/Reconciliation/TerminalSettlement.vue'),
                meta: {
                    title: 'Terminal Settlement',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_PAYCONNECT_SEARCH_RECON']
                },
            },
            {
                path: '/matched',
                name: 'matched',
                component: () => import('@/views/modules/Reconciliation/matched.vue'),
                meta: {
                    title: 'Matched Transactions',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_PAYCONNECT_SEARCH_RECON']
                },
            },
            {
                path: '/unmatched-transactions',
                name: 'unmatched-transactions',
                component: () => import('@/views/modules/Reconciliation/unmatchedTransactions.vue'),
                meta: {
                    title: 'Unmatched Transactions',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/cliqq-remittance',
                name: 'cliqq-remittance',
                component: () => import('@/views/modules/Reconciliation/cliqqRemittanceManagement.vue'),
                meta: {
                    title: 'Cardless withdrawal Transactions',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_CASHOUT_SEARCH_TRANSACTION', 'ROLE_CASHOUT_UPDATE_TRANSACTION']
                },
            },
            {
                path: '/upload-merchant-csv',
                name: 'upload-merchant-csv',
                component: () => import('@/views/modules/Reconciliation/uploadMerchantCSV.vue'),
                meta: {
                    title: 'Upload Merchant CSV',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/declined-transactions',
                name: 'declined-transactions',
                component: () => import('@/views/modules/Reconciliation/declinedTransactions.vue'),
                meta: {
                    title: 'Declined Transactions',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/settled',
                name: 'settled',
                component: () => import('@/views/modules/Reconciliation/settled.vue'),
                meta: {
                    title: 'Settlement Batch',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/bank-management',
                name: 'bank-management',
                component: () => import('@/views/modules/Reconciliation/bankManagement.vue'),
                meta: {
                    title: 'Bank Management',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/bank-account-management',
                name: 'bank-account-management',
                component: () => import('@/views/modules/Reconciliation/bankAccountManagement.vue'),
                meta: {
                    title: 'Bank Account Management',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/decline-reason-management',
                name: 'decline-reason-management',
                component: () => import('@/views/modules/Reconciliation/declineReasonManagement.vue'),
                meta: {
                    title: 'Decline Reason Management',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/merchant-parameters',
                name: 'merchant-parameters',
                component: () => import('@/views/modules/Reconciliation/merchantParameters.vue'),
                meta: {
                    title: 'Merchant Parameters',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/merchant-channel',
                name: 'merchant-channel',
                component: () => import('@/views/modules/Reconciliation/merchantChannels.vue'),
                meta: {
                    title: 'Merchant Channels',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/payment-agent-merchants',
                name: 'payment-agent-merchants',
                component: () => import('@/views/modules/Reconciliation/paymentAgentMerchants.vue'),
                meta: {
                    title: 'Payment Agent Merchants',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
            {
                path: '/visa-settlement-upload',
                name: 'visa-settlement-upload',
                component: () => import('@/views/modules/Reconciliation/visaSettlementUpload.vue'),
                meta: {
                    title: 'Visa CTF Upload',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_RECON']
                },
            },
        ],
    },
    {
        path: '/reports',
        alias: '/reports',
        name: 'reports',
        component: () => import('@/views/modules/Reports/index.vue'),
        meta: {
            title: 'Reports',
            icon: 'mdi-chart-box',
            roles: ['ROLE_PAYCONNECT_SEARCH_PAYMENT_REPORT', 'ROLE_PAYCONNECT_SEARCH_MERCHANT_REPORT', 'ROLE_TDM_SEARCH_TRANSACTION']
        },
        children: [
            {
                path: '/payment-reports',
                name: 'payment-reports',
                component: () => import('@/views/modules/Reports/payconnectReport.vue'),
                meta: {
                    title: 'Payment Reports',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_PAYCONNECT_SEARCH_PAYMENT_REPORT']
                },
            },
            {
                path: '/merchant-reports',
                name: 'merchant-reports',
                component: () => import('@/views/modules/Reports/payconnectReport.vue'),
                meta: {
                    title: 'Merchant Reports',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_PAYCONNECT_SEARCH_MERCHANT_REPORT']
                },
            },
            {
                path: '/visa-settlement-reports',
                name: 'visa-settlement-reports',
                component: () => import('@/views/modules/Reports/visaSettlementReport.vue'),
                meta: {
                    title: 'Visa Settlement Report',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_TDM_SEARCH_TRANSACTION']
                },
            },
        ],
  },
  {
    path: '/settings',
    alias: '/',
    name: 'settings',
    component: () => import('@/views/modules/Settings/index.vue'),
    meta: {
      title: 'Settings',
      icon: 'mdi-cog',
      roles: ['ROLE_TDM_VIEW_SETTINGS']
    },
    children: [
          {
            path: '/settings-terminal',
            name: 'settings-terminal',
            component: () => import('@/views/modules/Settings/terminal.vue'),
            meta: {
              title: 'Terminals',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_TERMINAL']
            },
          },
          {
            path: '/settings-card-scheme',
            name: 'settings-card-scheme',
            component: () => import('@/views/modules/Settings/cardScheme.vue'),
            meta: {
              title: 'Card Scheme',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_TERMINAL']
            },
          },
          {
            path: '/settings-card-definition',
            name: 'settings-card-definition',
            component: () => import('@/views/modules/Settings/cardDefinition.vue'),
            meta: {
              title: 'Card Definition',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_TERMINAL']
            },
          },
          {
            path: '/settings-currency',
            name: 'settings-currency',
            component: () => import('@/views/modules/Settings/currency.vue'),
            meta: {
              title: 'Currency',
              icon: 'mdi-file-document',
              // roles: ['ROLE_TDM_VIEW_CURRENCY']
              roles: ['ROLE_TDM_VIEW_TERMINAL']
            },
          },
        //   {
        //     path: '/settings-maya-bin-range',
        //     name: 'settings-maya-bin-range',
        //     component: () => import('@/views/modules/Settings/mayaBinRange.vue'),
        //     meta: {
        //       title: 'Maya Bin Range',
        //       icon: 'mdi-file-document',
        //       roles: ['ROLE_TDM_VIEW_TERMINAL']
        //     },
        //   },
          {
            path: '/settings-emv',
            name: 'settings-emv',
            component: () => import('@/views/modules/Settings/emv.vue'),
            meta: {
              title: 'EMV',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_TERMINAL']
            },
          },
          {
            path: '/settings-acquirer',
            name: 'settings-acquirer',
            component: () => import('@/views/modules/Settings/acquirer.vue'),
            meta: {
              title: 'Acquirer',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_TERMINAL']
            },
          },
          {
            path: '/settings-branch',
            name: 'settings-branch',
            component: () => import('@/views/modules/Settings/branch.vue'),
            meta: {
              title: 'Locations',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_BRANCH']
            },
          },
          {
            path: '/settings-payment-agent',
            name: 'settings-payment-agent',
            component: () => import('@/views/modules/Settings/paymentAgent.vue'),
            meta: {
              title: 'Payment Agents',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_PAYMENT_AGENT']
            },
          },
          {
            path: '/settings-device-type',
            name: 'settings-device-type',
            component: () => import('@/views/modules/Settings/deviceType.vue'),
            meta: {
              title: 'Device Types',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_DEVICE_TYPE']
            },
          },
          {
            path: '/settings-ticket',
            name: 'settings-ticket',
            component: () => import('@/views/modules/Settings/ticket.vue'),
            meta: {
              title: 'Tickets',
              icon: 'mdi-file-document',
              roles: ['ROLE_TDM_VIEW_TICKET']
            },
          },
          {
            path: '/settings-store-ip',
            name: 'settings-store-ip',
            component: () => import('@/views/modules/Settings/storeIp.vue'),
            meta: {
              title: 'Store Ips',
              icon: 'mdi-file-document',
              roles: ['ROLE_PAYCONNECT_VIEW_STORE_IP']
            },
          },
          {
            path: '/settings-merchant-commission',
            name: 'settings-merchant-commission',
            component: () => import('@/views/modules/Settings/merchantCommission.vue'),
            meta: {
              title: 'Merchant Discount Rate',
              icon: 'mdi-file-document',
              roles: ['ROLE_PAYCONNECT_VIEW_MERCHANT_COMMISSION']
            },
          },
          {
            path: '/settings-adjustment',
            name: 'settings-adjustment',
            component: () => import('@/views/modules/Settings/adjustments.vue'),
            meta: {
              title: 'Merchant Service Fee',
              icon: 'mdi-file-document',
              roles: ['ROLE_PAYCONNECT_VIEW_ADJUSTMENTS']
            },
          },
          {
            path: '/settings-wallet-accounts',
            name: 'settings-wallet-accounts',
            component: () => import('@/views/modules/Settings/walletAccounts.vue'),
            meta: {
              title: 'Wallet Accounts',
              icon: 'mdi-file-document',
              roles: ['ROLE_PAYCONNECT_VIEW_ADJUSTMENTS']
            },
          },
          {
            path: '/settings-wallet-credentials',
            name: 'settings-wallet-credentials',
            component: () => import('@/views/modules/Settings/walletCredentials.vue'),
            meta: {
              title: 'Wallet Tenant',
              icon: 'mdi-file-document',
              roles: ['ROLE_PAYCONNECT_VIEW_ADJUSTMENTS']
            },
          },
        //   {
        //     path: '/settings-visa-bin-range',
        //     name: 'settings-visa-bin-range',
        //     component: () => import('@/views/modules/Settings/visaBinRange.vue'),
        //     meta: {
        //       title: 'Visa Bin Range',
        //       icon: 'mdi-file-document',
        //       roles: ['ROLE_TDM_VIEW_TERMINAL']
        //     },
        //   },
        ],
    },
    {
        path: '/billers-dashboard',
        alias: '/billers-dashboard',
        name: 'billers-dashboard',
        component: () => import('@/views/modules/Reports/index.vue'),
        meta: {
            title: `Biller's Dashboard`,
            icon: 'mdi-wallet',
            roles: ['ROLE_PAYCONNECT_SEARCH_PAYMENT_REPORT', 'ROLE_PAYCONNECT_SEARCH_MERCHANT_REPORT']
        },
        children: [
            {
                path: '/accounts',
                name: 'accounts',
                component: () => import('@/views/modules/BillerDashboard/accounts.vue'),
                meta: {
                    title: 'Accounts',
                    icon: 'mdi-wallet',
                    roles: ['ROLE_PAYCONNECT_SEARCH_PAYMENT_REPORT']
                },
            },
            {
                path: '/bills',
                name: 'bills',
                component: () => import('@/views/modules/BillerDashboard/bills.vue'),
                meta: {
                    title: 'Bills',
                    icon: 'mdi-wallet',
                    roles: ['ROLE_PAYCONNECT_SEARCH_PAYMENT_REPORT']
                },
            },
            {
                path: '/payment-methods',
                name: 'payment-methods',
                // component: () => import('@/views/modules/Reports/payconnectReport.vue'),
                meta: {
                    title: 'Payment Methods',
                    icon: 'mdi-wallet',
                    roles: ['ROLE_PAYCONNECT_SEARCH_PAYMENT_REPORT']
                },
            },
            {
                path: '/upload-file',
                name: 'upload-file',
                component: () => import('@/views/modules/BillerDashboard/uploadFile.vue'),
                meta: {
                    title: 'Upload CSV File',
                    icon: 'mdi-wallet',
                    roles: ['ROLE_PAYCONNECT_SEARCH_PAYMENT_REPORT']
                },
            },
        ],
    },
    {
        path: '/recon-threshold',
        name: 'recon-threshold',
        component: () => import('@/views/modules/Threshold/index.vue'),
        meta: {
            title: 'Threshold Management',
            icon: 'mdi-bell-alert-outline',
            roles: ['ROLE_VIEW_THRESHOLD']
        },
        children: [
            {
                path: '/threshold',
                name: 'threshold',
                component: () => import('@/views/modules/Threshold/threshold.vue'),
                meta: {
                    title: 'Threshold',
                    icon: 'mdi-file-document',
                    roles: ['any']
                },
            },
            {
                path: '/threshold/merchant-filter',
                name: 'merchant-filter',
                component: () => import('@/views/modules/Threshold/merchantFilter.vue'),
                meta: {
                    title: 'Merchant Filter',
                    icon: 'mdi-file-document',
                    roles: ['any']
                },
            },
            {
                path: '/threshold/disabled-store',
                name: 'disabled-store',
                component: () => import('@/views/modules/Threshold/disabledStore.vue'),
                meta: {
                    title: 'Disabled Stores',
                    icon: 'mdi-file-document',
                    roles: ['any']
                },
            },
            {
                path: '/threshold/store-event-log',
                name: 'store-event-log',
                component: () => import('@/views/modules/Threshold/storeEventLog.vue'),
                meta: {
                    title: 'Store Event Log',
                    icon: 'mdi-file-document',
                    roles: ['any']
                },
            }
        ],
    },
    {
        path: '/batch-topup',
        name: 'batch-topup',
        component: () => import('@/views/modules/Threshold/index.vue'),
        meta: {
            title: 'Top-Up',
            icon: 'mdi-bell-alert-outline',
            roles: ['ROLE_VIEW_THRESHOLD']
        },
        children: [
            {
                path: '/batchTopup',
                name: 'batchTopup',
                component: () => import('@/views/modules/Topup/batchTopUp.vue'),
                meta: {
                    title: 'Batch Top-Up',
                    icon: 'mdi-file-document',
                    roles: ['any']
                },
            },
        ],
    },
    {
        path: '/circuit-breaker',
        name: 'circuit-breaker',
        component: () => import('@/views/modules/CircuitBreaker/index.vue'),
        meta: {
            title: 'Circuit Breaker',
            icon: 'mdi-cog',
            roles: ['any']
        },
        children: [
            {
                path: '/update-configuration',
                name: 'updatedConfiguration',
                component: () => import('@/views/modules/CircuitBreaker/updateConfiguration.vue'),
                meta: {
                    title: 'Update Configuration',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_VIEW_THRESHOLD']
                }
            },
            {
                path: '/stores',
                name: 'storeManagement',
                component: () => import('@/views/modules/CircuitBreaker/stores.vue'),
                meta: {
                    title: 'Store Management',
                    icon: 'mdi-file-document',
                    roles: ['ROLE_VIEW_THRESHOLD']
                },
            },
            {
                path: '/sale-threshold-configuration',
                name: 'salethresholdconfiguration',
                component: () => import('@/views/modules/CircuitBreaker/storeSaleThreshold.vue'),
                meta: {
                    title: 'Sale Store Threshold',
                    icon: 'mdi-file-document',
                    roles: ['any']
                }
            },
        ],
    },
    {
        path: '/401',
        alias: '/401',
        name: '401',
        component: () => import('@/views/errors/401.vue'),
        hidden: true,
        meta: {
            roles: ['any']
        },
    },
];

export default new Router({
    mode: 'history', // gh-pages
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
});

export const asyncRoutes = [
    /** When your routing table is too long, you can split it into small modules */
    // permissionRouter,
    // vuetifyRouter,
    // ...componentsRouter,
    // nestedRouter,
    // errorsRouter,
    { path: '*', redirect: '/error/404', hidden: true },
];
